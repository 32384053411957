// ==============================
// Resources
// ==============================

.resource-listing {
  margin-top: 2.1em;
  .views-group {
    margin-bottom: 2.1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .views-group-rows {
    @include grid-container;
  }
  .views-row {
    @include grid-tile(3);
    font-size: 0.8em;
  }
  .views-field-field-image {
    margin-bottom: 1em;
    border: $border-input;
  }
  h4 {
    text-transform: none;
    a {
      @include link($asphalt, $cis-blue);
      font-weight: 600;
    }
  }
  .views-field-body {
    margin-bottom: 0.3em;
    .field-content {
      @include last-item-margin;
    }
  }
  // .views-field-path {
  //   a {
  //     font-weight: 600;
  //   }
  // }
}
