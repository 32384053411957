// =========================
// Colors
// =========================

$white: #fff;
$black: #000;

// Defined colors
$cis-orange: #ff9700;
$cis-blue: #0d3673;
// $denim: #6e82a6;
$denim: #587093;
$cloud: #e7eaef;
$lime: #1aff6b;
$avocado: #1ee29b;
$sky: #40d6ff;
$asphalt: #2e343a;
$navy: #0a2749;
$blood: #cc2b2b;

// Extras
$nepal: #99a8c2;
