// =========================
// Form elements
// =========================

// =========================
// Standard input styles
// =========================

input,
textarea,
select {
  display: block;
  width: 100%;
  padding: 0.6em;
  margin: 0 0 1.3em;
  border: $border-input;
  border-radius: 0;
  box-shadow: none;
  background: $white;
  color: $asphalt;
  font-family: $roboto;
  // font-size: 0.8em;
  font-weight: 300;
  &::placeholder {
    color: $cloud;
  }
}

textarea {
  resize: vertical;
}

.form-textarea-wrapper {
  .grippie {
    display: none;
  }
}

// =========================
// Labels
// =========================

label {
  display: block;
  margin: 0 0 0.3em;
  color: $cis-blue;
  font-size: 0.8em;
  line-height: 1.5;
  font-weight: 400;
}

// =========================
// Submit buttons
// =========================

input[type=submit] {
  @include button;
  width: auto;
  margin: 1em auto;
  appearance: none;
}

// =========================
// Selects
// =========================

select {
  padding-right: 3em;
  background: $white svg-load('../images/icons/arrow-down.svg', fill = $cis-blue) no-repeat 90% center;
  background-position: right 1em center;
  background-size: auto 40%;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
}

// =========================
// Checkboxes and radios
// =========================

input[type=checkbox],
input[type=radio] {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  clip: rect(0 0 0 0);
  + label {
    display: block;
    position: relative;
    color: $asphalt;
    font-size: 1em;
    font-weight: 300;
    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      width: 1em;
      height: 1em;
      margin-right: 0.3em;
      margin-bottom: 0.19em;
      border: $border-input;
      background: $white;
    }
  }
  &:focus {
    + label {
      &::before {
        background: rgba($denim, 0.25);
      }
    }
  }
  &:checked {
    + label {
      &::before {
        box-shadow: inset 0 0 0 0.14em $white;
        background: $cis-blue;
      }
    }
  }
}

input[type=radio] {
  + label {
    &::before {
      border-radius: 100%;
    }
  }
}

// =========================
// Webform elements
// =========================

.form-item,
.form-actions {
  display: block;
  position: relative;
  margin: 0 0 1.3em;
  input {
    margin: 0;
  }
}

.marker,
.form-required {
  color: $cis-orange;
}

// =========================
// Contact form
// =========================

.contact-form {
  max-width: 34em;
  padding: 1.6em $gutter * 2;
  margin: 2.1em auto;
  background: $cloud;
  input[type=submit] {
    display: block;
    margin: 0 auto;
  }
}
