// =========================
// Carousel
// =========================

.owl-carousel {
  position: relative;
  padding: 0 $gutter-desk;
}

// =========================
// Navigation arrows
// =========================

.owl-prev,
.owl-next {
  @include link($cis-blue, $denim);
  position: absolute;
  top: 37%; // Visually align with content
  transform: translateY(-100%);
  svg {
    display: block;
    width: 1.3em;
    height: 1.3em;
    padding: 0;
    margin: 0;
  }
}

.owl-prev {
  left: 0;
}

.owl-next {
  right: 0;
}

// =========================
// Dot navigation
// =========================

.owl-dots {
  margin-top: 1.6em;
}

.owl-dot {
  @include transition(opacity);
  display: inline-block;
  opacity: 0.5;
  width: 0.6em;
  height: 0.6em;
  margin: 0.3em;
  border-radius: 100%;
  background: $cis-blue;
  &:hover {
    opacity: 0.75;
  }
  &.active {
    opacity: 1;
  }
}

// =========================
// Owl content
// =========================

.owl-item {
  .field-item {
    @include last-item-margin;
  }
}
