// =========================
// Typography
// General type styles that may be overriden by components
// =========================

*::selection {
  background: $avocado;
  color: $white;
}

html {
  // font-size: 62.5%;
  font-size: 55%;
  @media screen and (min-width: 81.25em) {
    font-size: 62.5%;
  }
  @media screen and (max-width: $palm) {
    font-size: 48%;
  }
}

body {
  @include fontsmoothing(on);
  color: $asphalt;
  font-family: $roboto;
  font-size: 2rem;
  line-height: (30 / 20);
  font-weight: 300;
  font-style: normal;
}

// =========================
// Headings
// =========================

h1,
h2,
h3,
h4 {
  margin: 0 0 0.4em;
  color: $cis-blue;
  font-family: $sofia;
  line-height: 1.3;
  + h1,
  + h2,
  + h3 {
    margin-top: 1em; // Extra space between sibling headings
  }
}

h1 {
  font-size: 2.6em;
  line-height: 1.25;
  font-weight: 700;
  @media screen and (max-width: $tablet) {
    font-size: 2.1em;
  }
}

h2 {
  font-size: 2.1em;
  line-height: 1.25;
  font-weight: 300;
  @media screen and (max-width: $tablet) {
    font-size: 1.6em;
  }
}

h3 {
  font-size: 1.6em;
  line-height: 1.3125;
  font-weight: 400;
}

h4 {
  color: $asphalt;
  font-size: 1em;
  line-height: 1.5;
  font-weight: 600;
  text-transform: uppercase;
}

h5 {
  margin: 0 0 0.3em;
  font-size: 0.8em;
  line-height: 1.5;
  font-weight: 300;
}

// =========================
// Body
// =========================

p,
ul,
ol {
  margin: 0 0 1.5em;
}

strong,
b {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

// =========================
// Links and buttons
// =========================

a {
  @include link($denim, $cis-blue);
}

.content {
  a:not(button):not(.button) {
    word-wrap: break-word;
  }
}

button,
.button {
  @include button;
}

.button-arrow,
.arrow,
.readmore {
  @include link-arrow(after, $cis-orange);
}

// =========================
// Lists
// =========================

main, // Don't mess up lists like admin and messages
.cke_editable { // Make sure it also shows in ckeditor
  ol,
  ul {
    padding: 0 0 0 1em;
  }
  ul {
    @include ul;
  }
  li {
    margin: 0 0 1em;
  }
}

// =========================
// Quotes and cites
// =========================

blockquote,
cite {
  @include last-item-margin;
  padding: 0;
  margin: 0 0 1em;
  color: $cis-blue;
  font-size: 1.1em;
  line-height: 1.45;
  // font-weight: 400;
}

cite {
  font-style: italic;
  em,
  i {
    font-style: normal;
  }
}
