// =========================
// Modal windows
// =========================

.modal-wrapper {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 501; // Sit on top of contextual links
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($cloud, 0.9);
  transition: visibility 0s ease 0.19s,
              opacity 0.19s ease 0s;
}

.modal-active {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s, 0s;
}

.modal-inner {
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: $container-width;
  max-height: 80%;
  padding: 2.1em 1em;
  border-top: 1.6rem solid $cis-orange;
  box-shadow: $shadow;
  background: $white;
  transform: translate(-50%, -50%);
  -webkit-overflow-scrolling: touch; // Restore momentum scrolling on ios
  .closicon {
    @include inline-icon('closicon', $cis-blue, $denim);
    display: block;
    position: absolute;
    top: 0.5em;
    right: 1em;
    cursor: pointer;
    width: 1.4em;
    height: 1.4em;
    &::after {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
  @media screen and (max-width: $palm) {
    font-size: 0.8em;
  }
}
