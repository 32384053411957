// ==============================
// Share buttons
// ==============================

.share {
  @include link($cis-blue, $denim);
  display: inline-block;
  margin: 0 0.19em;
  svg {
    overflow: visible;
    width: 1.3em;
    height: 1.3em;
    margin: 0;
    @media screen and (max-width: $lap) {
      width: 2.1em;
      height: 2.1em;
    }
  }
}

// ==============================
// Share container
// ==============================

.share-container {
  @media screen and (max-width: $mobile-nav) {
    text-align: center;
  }
}
