// ==============================
// Footer
// ==============================

footer {
  padding: $section-gutter 0 $section-gutter / 2.1;
  p {
    margin: 0;
  }
  @media screen and (max-width: $mobile-nav) {
    text-align: center;
  }
}

.region-footer {
  font-size: 0.8em;
}

// ==============================
// Footer columns
// ==============================

.region-blockgroup-footer-columns {
  @include column-container;
  > * {
    @include column(4);
    // padding: 0 $gutter;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    @media screen and (max-width: $mobile-nav) {
      padding: 0;
    }
  }
  @media screen and (max-width: $mobile-nav) {
    display: block;
    text-align: center;
  }
  .block {
    > h2 { // Block title, match default h4
      color: $asphalt;
      font-size: 1em;
      line-height: (30 / 20);
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

// ==============================
// Footer social
// ==============================

.footer-social {
  display: inline-block;
  flex: 0 0 auto;
}

.social {
  // @include link-override;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin: 0 0.19em 0.19em;
  font-size: 1.6em;
  // text-align: center;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  svg {
    @include svg-icon;
  }
  @media screen and (max-width: $lap) {
    width: 1.6em;
    height: 1.6em;
  }
}

// ==============================
// Footer menu
// ==============================

.footer-menu {
  .menu-top {
    display: block;
    padding: 0;
    margin: 0;
  }
  .item-level-1 { // Override menu styles set for header
    > a,
    > .nolink {
      margin-bottom: 0.6em;
      &:not(.button) {
        padding: 0;
      }
    }
  }
}

// ==============================
// Footer accreditations/memberships
// ==============================

.footer-accreditations {
  margin-bottom: 3em;
  text-align: center;
  .content {
    > * {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.footer-accreditations,
.footer-memberships {
  > h2 { // Block title
    margin-bottom: 1em;
    color: $cis-blue;
    font-size: 1.6em;
    line-height: (48 / 32);
    font-weight: 400;
  }
  @media screen and (max-width: $lap) {
    .content {
      @include overflow-container;
      > * {
        @include overflow-items;
        > a,
        > img {
          width: 20%;
          min-width: 8em;
        }
      }
    }
  }
}

// ==============================
// Colophon
// ==============================

.colophon {
  text-align: center;
}
