// ==============================
// Announcement
// ==============================

.announcements {
  position: relative;
  background: $cis-blue;
  color: $white;
  transition: margin 0.3s ease 1s;
  .content {
    position: relative;
  }
  .closicon { // The link
    @include link($white, $sky);
    display: block;
    position: absolute;
    top: 0.5em;
    // right: 1em;
    right: 0;
    cursor: pointer;
  }
  .icon-closicon { // The actual svg
    width: 1em;
    height: 1em;
    margin: 0;
  }
  .arrow {
    @include link($sky);
    @include link-arrow($color: $cis-orange);
    font-weight: 700;
    white-space: nowrap;
  }
  .views-row {
    @include last-item-margin;
  }
}

.announcements-closed {
  display: none;
  // margin-top: -5em;
  // transition-delay: 0s;
}

.view-announcements {
  // padding: 0.6em $gutter-desk;
  padding: 0.6em 2.1em 0.6em 0;
  // background: $cis-blue;
  // color: $white;
  font-size: 0.8em;
  line-height: 1.5;
  font-weight: 400;
}

// ==============================
// Front page announcements
// ==============================

.front {
  .announcements {
    margin-top: -5em; // Magic number
  }
  .announcements-active {
    margin-top: 0;
  }
}
