// ==============================
// News items
// ==============================

.node-news {
  .field-name-field-date {
    display: block;
    margin-bottom: 1em;
    font-weight: 600;
  }
}

// ==============================
// News listing
// ==============================

.news-listing {
  margin-top: 3.4em;
  .views-row {
    padding-bottom: 2.1em;
    margin-bottom: 2.1em;
    border-bottom: $border;
    h4 {
      text-transform: none;
      a {
        @include link($asphalt, $cis-blue);
        font-weight: 600;
      }
    }
  }
  .views-field-field-date {
    display: block;
    margin-bottom: 0.3em;
    // font-weight: 600;
  }
}
