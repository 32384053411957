// =========================
// Search page
// =========================

.page-search {
  .search-form {
    // margin-bottom: 2.1em;
    .form-item {
      margin: 0;
    }
    input.form-submit {
      margin: 0;
    }
  }
}

.search-advanced {
  display: none;
}

#search-form { // The form on the results page
  margin-bottom: 2.1em;
}

.search-form {
  // margin-bottom: 2.1em;
  // margin: 0;
  .container-inline {
    display: flex;
    align-items: flex-end;
    // justify-content: space-between;
    justify-content: flex-start;
  }
  .form-item {
    flex: 1;
  }
  input.form-submit {
    margin: 0.9em auto; // Compensate for the border of the adjacent input
  }
}

.search-results {
  padding: 0;
  margin: 0;
  li {
    padding-bottom: 1em;
    margin-bottom: 2.6em;
    border-bottom: $border;
    &:last-child {
      border-bottom: 0;
    }
  }
  .search-snippet-info {
    padding: 0;
  }
}
