// ==============================
// Case study listing
// ==============================

.case-studies-listing {
  margin-top: 3.4em;
  .views-row {
    padding-bottom: 2.1em;
    margin-bottom: 2.1em;
    border-bottom: $border;
    h4 {
      text-transform: none;
      a {
        @include link($asphalt, $cis-blue);
        font-weight: 600;
      }
    }
    .views-field-body {
      .field-content {
        @include last-item-margin;
        margin-bottom: 0.3em;
      }
    }
  }
}
