// ==============================
// Layout
// General layout styles that may be overriden by components
// ==============================

* {
  box-sizing: border-box;
}

html,
body {
  // overflow-x: hidden;
  // overflow-y: auto; // Breaks scroll animation
  width: 100%;
  // height: 100%;
  // -webkit-overflow-scrolling: touch;
}

body {
  // padding-top: $header-height;
  // display: flex;
  // flex-direction: column;
  // min-height: 100vh;
}

main {
  flex: 1;
  padding-top: $header-height;
  @media screen and (max-width: $mobile-nav) {
    padding-top: $header-height-mobile;
  }
}

.cf {
  @include cf;
}

// ==============================
// Images
// ==============================

img,
svg {
  max-width: 100%;
  height: auto;
}

.field {
  img {
    @media screen and (max-width: $lap) {
      display: block;
      float: none !important; // In case the wysiwyg has a float set
      padding: 0 !important;
      margin: 0 auto !important;
    }
  }
}

p {
  img:only-child { // Select floated images in the wysiwyg
    @media screen and (max-width: $palm) {
      display: block;
      margin: 0 auto;
    }
  }
}

svg {
  max-height: 100%;
}

.icon {
  @include svg-icon-inline;
}

.image-grow {
  display: block;
  overflow: hidden;
  &.grow-full {
    overflow: visible;
  }
  img {
    @include transition(transform, 0.19s);
    display: block;
    transform-origin: center;
    backface-visibility: hidden;
  }
  &:hover {
    img {
      transform: scale(1.25);
    }
  }
}

// ==============================
// Figures
// ==============================

figure {
  text-align: center;
  @media screen and (max-width: $palm) {
    float: none !important; // Same check as img for wysiwygs
  }
}

figcaption {
  font-size: 0.8em;
  font-weight: 600;
}

// ==============================
// Columns
// ==============================

.column-container,
.two-col,
.three-col {
  @include column-container;
}

[class*=col-] {
  @include last-item-margin;
  &:only-child {
    width: 100%;
  }
  @media screen and (max-width: $palm) {
    width: 100%;
  }
}

// Generate columns
@for $i from 1 through 12 {
  .col-#{$i} {
    @include column($i);
  }
}

// ==============================
// Containers
// ==============================

.container {
  @include container;
}

.embedded-video {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: (9 / 16) * 100%;
  margin: 0 auto;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// ==============================
// Sections
// ==============================

section {
  padding: $section-gutter 0;
}

// ==============================
// Anchor links
// ==============================

// a[href^='#'] {
//   &::before {
//     content: '';
//     display: block;
//     visibility: hidden;
//     height: $header-height;
//     margin-top: -$header-height;
//     border: 0;
//   }
// }

// ==============================
// Horizontal rules
// ==============================

hr {
  @include inline-icon('hr-cisive', $cis-orange, $pseudo: 'before');
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 0;
  padding: 0;
  margin: 4.2em 0;
  border: 0;
  &::before {
    vertical-align: middle;
    width: 2.1em;
    height: 2.1em;
  }
  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    flex: 1;
    // width: calc(100% - 4.5em);
    height: 0.4rem;
    margin-left: 0.6em;
    background: rgba($denim, 0.25);
  }
}

// Front page hrs
.front {
  hr {
    @include inline-icon('hr-front', $denim, $pseudo: 'before');
    margin: 5.5em 0;
    &::before {
      width: 4.2em;
      height: 4.2em * 0.433248144; // Ratio based on viewbox of svg
    }
    &::after {
      width: calc(100% - 4.5em);
      background: $denim;
    }
  }
}

// Basic hr style
[class*=col-] {
  hr {
    display: block;
    margin: 1.6em 0;
    border-top: $border;
    &::before,
    &::after {
      content: none;
    }
  }
}
