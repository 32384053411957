// =========================
// Leadership
// =========================

.leadership {
  margin-top: 3.4em;
  .view-content {
    @include grid-container;
  }
  .views-row {
    @include grid-tile;
  }
  .modal-preview {
    .image-grow {
      cursor: pointer;
      margin-bottom: 0.8em;
    }
    h4 {
      margin-bottom: 0;
      text-transform: none;
    }
    h5 {
      margin-bottom: 0;
    }
  }
  .modal-inner {
    > .column-container {
      margin-bottom: 0;
    }
    .leadership-image {
      max-width: 16em;
      margin: 0 auto;
      text-align: center;
    }
    .leadership-name {
      margin-bottom: 0;
    }
    .leadership-title {
      margin-bottom: 1em;
      color: $cis-blue;
    }
    .leadership-bio {
      @include last-item-margin;
    }
  }
}
