// =========================
// Utilities
// Helpful functions and global styles
// =========================

// =========================
// Layout
// =========================

// Clearfix
@mixin cf () {
  clear: both;
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
  }
}

// Remove the bottom margin from the last item of a section
@mixin last-item-margin () {
  > *:last-child {
    margin-bottom: 0;
  }
}

// SVG icons
@mixin svg-icon () {
  display: block;
  overflow: visible;
  width: 100%;
  height: 100%;
}

// Inline svg icons
@mixin svg-icon-inline () {
  display: inline-block;
  vertical-align: baseline;
  width: 1em;
  height: 1em;
  margin: 0 0 0 0.3em;
  overflow: visible;
}

// Place inline icon after element, with an active color and active trigger
@mixin inline-icon ($icon, $color, $active-color: null, $active: ':hover', $pseudo: 'after') {
  &::#{$pseudo} {
    @include svg-icon-inline;
    // vertical-align: middle;
    // content: '';
    content: svg-load('../images/icons/#{$icon}.svg', fill = $color);
    width: 0.6em;
    height: 0.8em;
    margin: 0 0 0.19em;
    // background: svg-load('../images/icons/#{$icon}.svg', fill = $color) no-repeat left center;
    // background-size: 100% 100%;
    background-size: contain;
    transition: none;
    backface-visibility: hidden;
    @if $pseudo == 'after' {
      margin-left: 0.3em;
    } @else {
      margin-right: 0.3em;
    }
  }
  @if $active-color {
    &#{$active}::#{$pseudo} {
      // background-image: svg-load('../images/icons/#{$icon}.svg', fill = $active-color);
      content: svg-load('../images/icons/#{$icon}.svg', fill = $active-color);
    }
  }
}

// Column container
@mixin column-container () {
  @include last-item-margin;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2.1em;
}

// Generate column sizes
@mixin column ($size: 6) {
  // @include last-item-margin;
  display: inline-block;
  $width: (($size / 12) * 100) - ($gutter * 2.1); // Get the percentage, and remove the gutter percentage
  flex: 1 1 auto;
  width: $width;
  @if $size != 12 {
    margin-right: $gutter * 2.1;
  }
  &:only-child {
    width: 100%;
  }
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: $mobile-nav) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2.1em;
  }
}

@mixin container () {
  @include last-item-margin;
  width: 100%;
  max-width: $container-width;
  padding: 0 $gutter;
  margin: 0 auto;
  .container {
    padding: 0;
  }
  @media screen and (min-width: $desk) {
    padding: 0 $gutter-desk;
  }
}

// Grid layout
// Note: Not a CSS grid, just tiled content that looks like a grid
@mixin grid-container () {
  display: flex;
  flex-wrap: wrap;
  margin: 0 (-$gutter); // Negate the left and right margins of the first and last grid-tiles for each row
}

@mixin grid-tile ($number: 4) { // Allow for different numbers of grid items per row
  $width: 100% / $number;
  flex: 0 0 auto;
  width: calc(#{$width} - #{$gutter} * 2);
  // width: calc(25% - #{$gutter} * 2);
  // width: 25%;
  // max-width: calc(25% - #{$gutter} * 2);
  margin: 0 $gutter 1.3em;
  // cursor: pointer;
  // &:nth-of-type(4n + 1) {
  //   margin-left: 0;
  // }
  // &:nth-of-type(4n) {
  //   margin-right: 0;
  // }
  // &:last-child {
  //   margin-right: 0;
  // }
  @media screen and (max-width: $tablet) {
    width: calc(50% - #{$gutter} * 2);
  }
}

// Elements that can be scrolled horizontally
@mixin overflow-container () {
  overflow-x: hidden;
  overflow-y: visible;
  width: 100% + ($gutter * 2);
  margin: 0 (-$gutter);
  -webkit-overflow-scrolling: touch;
}

@mixin overflow-items () {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: visible;
  width: 100%;
  margin: 0;
  padding: 0 $gutter;
}

// =========================
// Typography
// =========================

@mixin fontsmoothing ($value: on) {
  @if $value == on {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  } @else {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

@mixin white () {
  color: $white;
}

//
// Lists
//

@mixin ul () {
  li {
    position: relative;
    list-style: none;
    padding-left: 1em;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.5em;
      left: 0;
      width: 0.5em; // Same as link-arrow size
      height: 0.5em; // Same as link-arrow size
      background: svg-load('../images/icons/arrow-li.svg', fill = $nepal) no-repeat center;
      background-size: contain;
    }
  }
}

@mixin ul-override () {
  padding: 0;
  li {
    list-style: disc;
    padding: 0;
    margin: 0;
    &::before {
      content: none;
      display: none;
    }
  }
}

//
// Links
//

// Link progression: denim > cis-blue > lime > avocado

// Basic link styles, with optional color parameters
@mixin link ($color: null, $hover: null) {
  @include transition(color);
  font-weight: 400;
  text-decoration: none;
  @if $color {
    color: $color;
  }
  @if $hover {
    &:hover {
      color: $hover;
    }
  }
  &::before,
  &::after {
    @include transition(background);
  }
}

// For removing link styles on elements
@mixin link-override () {
  font-weight: inherit;
}

// Add an arrow to the end of the link
@mixin link-arrow ($pseudo: after, $color: inherit, $hover: $color) {
  &::#{$pseudo} {
    @include svg-icon-inline;
    @include transition(transform);
    content: '';
    width: 0.6em;
    height: 0.5em;
    margin-bottom: 0.03em;
    background-image: svg-load('../images/icons/arrow-right.svg', fill = $color);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  &:hover {
    &::#{$pseudo} {
      @if $hover != $color {
        background-image: svg-load('../images/icons/arrow-right.svg', fill = $hover);
      }
      @if $pseudo == after {
        transform: translateX(0.2em);
      }
    }
  }
}

// Button style
@mixin button (
  $background: $lime,
  $background-hover: $avocado,
  $color: $cis-blue,
  $color-hover: $color,
  $arrow-color: $color,
  $arrow-hover: $arrow-color
) {
  @include link-arrow(after, $arrow-color, $arrow-hover); // Override the hover color, too
  @include transition(background color);
  display: inline-block;
  // display: table; // Allow the button to be centered using margin auto
  padding: 0.5em 1em;
  border: 0;
  cursor: pointer;
  background: $background;
  color: $color;
  font-family: $roboto;
  font-size: 1.1em;
  line-height: (28 / 22);
  font-weight: 700;
  white-space: nowrap;
  backface-visibility: hidden;
  &:hover {
    outline: 0;
    background: $background-hover;
    color: $color-hover;
  }
  &:disabled,
  &[disabled=disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    &:hover {
      background: $background; // Make sure no hover states are applied
    }
  }
}

// =========================
// Animations
// =========================

// Consistent animation timing and easing
@mixin transition ($properties: all, $duration: 0.09s, $easing: ease) {
  @if length($properties) > 1 {

    $props: null;
    @each $p in $properties {
      $props: append($props, $p, comma);
    }

    transition-property: $props;
    transition-duration: $duration;
    transition-timing-function: $easing;

  } @elseif $properties == 'none' {
    transition: none;

  } @else {
    transition: $properties $duration $easing;
  }
}
