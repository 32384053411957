// =========================
// Font declarations
// =========================

$roboto: 'Roboto', sans-serif;
$sofia: 'Sofia Pro', sans-serif;

// =========================
// Sofia Pro
// =========================

$font-domain: '/sites/all/themes/master/src/fonts';

// @license
// MyFonts Webfont Build ID 3433343, 2017-08-07T10:53:51-0400
//
// The fonts listed in this notice are subject to the End User License
// Agreement(s) entered into by the website owner. All other parties are
// explicitly restricted from using the Licensed Webfonts(s).
//
// You may obtain a valid license at the URLs below.
//
// License: https://www.myfonts.com/viewlicense?type=web&buildid=3433343
// Licensed pageviews: 10,000
// Webfonts copyright: Copyright &#x00A9; Olivier Gourvat - Mostardesign Type Foundry, 2016. All rights reserved.
//
// © 2017 MyFonts Inc

// @import must be at top of file, otherwise CSS will not work
@import url("//hello.myfonts.net/count/34637f");

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_E_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_E_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_E_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_E_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_E_0.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_F_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_F_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_F_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_F_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_F_0.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_3_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_3_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_3_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_3_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_3_0.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_0_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_0_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_0_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_0_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_0_0.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_6_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_6_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_6_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_6_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_6_0.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_7_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_7_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_7_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_7_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_7_0.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_A_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_A_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_A_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_A_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_A_0.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_B_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_B_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_B_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_B_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_B_0.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_8_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_8_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_8_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_8_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_8_0.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_9_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_9_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_9_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_9_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_9_0.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_C_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_C_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_C_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_C_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_C_0.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_D_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_D_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_D_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_D_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_D_0.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_5_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_5_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_5_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_5_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_5_0.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_1_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_1_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_1_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_1_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_1_0.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_2_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_2_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_2_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_2_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_2_0.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('#{$font-domain}/sofia-pro/34637F_4_0.eot');
  src: url('#{$font-domain}/sofia-pro/34637F_4_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-domain}/sofia-pro/34637F_4_0.woff2') format('woff2'),
       url('#{$font-domain}/sofia-pro/34637F_4_0.woff') format('woff'),
       url('#{$font-domain}/sofia-pro/34637F_4_0.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
