// =========================
// Variables
// Globablly available base variables
// =========================

// =========================
// Layout
// =========================

$container-width-raw: 105; // Need a format without units
$container-width: $container-width-raw + rem;
$gutter: 2.6%;
$gutter-desk: 2.6em;
$section-gutter: 3.4em;
// $header-height: $section-gutter * 1.7;
$header-height: 11.5rem;
$header-height-mobile: $header-height * 0.6; // Compensate for header font-size change
// $header-gutter: $section-gutter / 4;
// $header-gutter: 1.3rem;
$header-gutter: 1rem;
$submenu-z: 101; // Used for positioning submenu, parent nav, and hover intent

$admin-height: 29px;
$slicknav-height: 47px;

$border: 0.4rem solid rgba($denim, 0.25);
$border-round: 0.3em;
// $border-input: 0.1rem solid rgba($denim, 0.25);
$border-input: 1px solid rgba($denim, 0.25);
// $border-input: 0.1rem solid $cloud;

$shadow: 0 0.19rem 0.3rem rgba($denim, 0.35);

// =========================
// Media query breakpoints
// =========================

// Em-based media queries are based on the window font size, 16px,
// not the document's font size, which is set to 10px.

// Variable names don't necessarily adhere strictly to the sizes they represent,
// but are meant to represent magnitude relatively
// Other potential names could be coffee sizes (short, tall, grande, venti, trenta)
$wrist: 30em; // 400
// 31.25em // 500
$palm: 37.5em; // 600
$tablet: 40.625em; // 650
$lap: 50em; // 800
$desk: ($container-width-raw * 10) / 16 + 'em'; // 1050

$mobile-nav: $lap;
$desk-nav: $lap + 0.1;

// =========================
// Animations
// =========================

$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
