// ==============================
// Header
// ==============================

// Good luck

// For switching around when parent nav items need to look like
// the nav top-level or larger parents
@mixin nav-expanded () {
  > a,
  > .nolink {
    &:not(.button) {
      padding: 0.25em 0.45em;
      color: $cis-blue;
      font-family: $sofia;
      font-size: 1.17em;
      // line-height: (30 / 20);
      line-height: (23 / 20);
      font-weight: 600;
      &.active,
      &.active-trail {
        font-weight: 800;
      }
      @media screen and (max-width: $mobile-nav) {
        padding: 0.3em $gutter;
      }
    }
  }
}

@mixin nav-expanded-nolink {
  @media screen and (max-width: $mobile-nav) {
    color: $denim !important;
    font-weight: 400 !important;
  }
}

header {
  @media screen and (max-width: $mobile-nav) {
    // font-size: 0.6em;
    font-size: 0.8em;
  }
}

.sticky-header {
  // overflow: hidden;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  // padding: $header-gutter 0 0; // No bottom padding for link borders
  padding: 0;
  box-shadow: $shadow;
  background: $white;
  @media screen and (max-width: $mobile-nav) {
    height: $header-height-mobile;
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @media screen and (max-width: $mobile-nav) {
    align-items: center;
  }
}

.region-header {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;
  justify-content: flex-end;
  height: 100%;
  padding: $header-gutter 0 0; // Bottom padding is handled by main-menu parent links
  @media screen and (max-width: $mobile-nav) {
    display: none;
    // overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    // align-content: flex-start;
    // visibility: hidden;
    // opacity: 0;
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // padding: $gutter;
    padding: 1em 0;
    border-top: 0.6em solid $cis-orange;
    background: $white;
    // font-size: 0.6em;
    -webkit-overflow-scrolling: touch; // Restore momentum scrolling on ios
    > * {
      width: 100%;
      &:first-child {
        padding-right: 3.4em;
      }
    }
  }
  @media screen and (max-width: $palm) {
    padding: 0.5em 0;
    font-size: 1.3em;
  }
}

@media screen and (max-width: $mobile-nav) {
  .menu-active {
    .region-header {
      // visibility: visible;
      // opacity: 1;
      display: block;
      z-index: 101;
    }
  }
}

// ==============================
// Logo
// ==============================

.logo {
  @include svg-icon;
  min-width: 4em;
  max-width: 11em;
  height: auto;
  // margin: $header-gutter 0;
  font-size: 1em;
  // margin-bottom: 0.6em; // Align visually with nav
  img {
    display: block;
    width: 100%;
  }
  @media screen and (max-width: $mobile-nav) {
    // margin-bottom: 0;
    width: 8em;
    min-width: 0;
  }
}

// ==============================
// Navicon
// ==============================

.navicon {
  @include link($cis-blue, $cis-orange);
  display: none;
  position: relative;
  z-index: 105;
  flex: 0 0 auto;
  cursor: pointer;
  width: 1em;
  height: 1em;
  // margin-left: 1em;
  background: $white; // So elements underneath don't show
  // color: $gull-gray;
  font-size: 2.6em;
  line-height: 1;
  text-transform: uppercase;
  svg {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .icon-closicon {
    display: none;
    // height: 0.8em;
    top: 0.2em;
  }
  @media screen and (max-width: $mobile-nav) {
    display: block;
  }
}

.menu-active {
  .navicon {
    .icon-navicon {
      display: none;
    }
    .icon-closicon {
      display: block;
    }
  }
}

// ==============================
// General menu
// ==============================

.menu-block-wrapper {
  line-height: 1;
  ul {
    // @include ul-override;
    margin: 0;
  }
  li {
    display: block;
    list-style: none;
    margin: 0;
    &.active {
      > a {
        // color: $shuttle-gray;
        // font-weight: 600;
      }
    }
  }
  a,
  .nolink {
    &:not(.button) {
      display: block;
    }
  }
  @media screen and (max-width: $mobile-nav) {
    // font-size: 2.1em;
    .expanded {
      @include nav-expanded;
      margin-bottom: 0.6em;
    }
  }
  // @media screen and (max-width: $palm) {
  //   font-size: 1.6em;
  // }
}

// ==============================
// General menu (top-level)
// ==============================

// Only inline the links for the header. Everywhere else it looks like the footer menu
header {
  .menu-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $mobile-nav) {
      display: block;
      padding: 0;
      margin: 0;
    }
  }
  .item-level-1 {
    // margin: 0 0.3em; // Little spacing for the border animation
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      .menu-level-2 {
        visibility: visible;
        opacity: 1;
        // transition-delay: 0s, 0s;
      }
    }
    > a,
    > .nolink {
      position: relative;
      z-index: $submenu-z + 1; // More than menu-level-2
      &:not(.button) {
        display: block;
        padding: 0.6em;
        white-space: nowrap;
        // text-align: center;
        @media screen and (max-width: $mobile-nav) {
          white-space: normal;
          padding: 0.3em $gutter;
        }
      }
      &.button {
        margin-left: 0.6em;
        @media screen and (max-width: $mobile-nav) {
          margin: 0 auto;
          font-size: 1.3em;
        }
      }
    }
  }
}

// ==============================
// General menu (submenu)
// ==============================

.menu-level-2 { // Second level is the only one that drops down
  display: block;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: $submenu-z;
  top: 100%;
  left: 50%;
  width: auto;
  min-width: 11em;
  max-width: 60vw;
  padding: 1em 0.8em;
  margin: 0;
  border-top: 0.2em solid $cis-orange;
  box-shadow: $shadow;
  background: $white;
  text-align: left;
  transform: translateX(-50%);
  // transition: visibility 0s ease 0.6s,
  //             opacity 0s ease 0.6s;
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: -3em;
    left: 50%;
    width: 140%;
    height: 100%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: $mobile-nav) {
    visibility: visible;
    opacity: 1;
    position: static;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    padding: 0;
    border-top: 0;
    box-shadow: none;
    transform: none;
  }
}

.submenu {
  .submenu {
    display: block;
    padding: 0;
    margin: 0;
  }
  li {
    width: 100%;
    // &.has-children
  }
  a,
  .nolink {
    display: block;
    position: relative;
    padding: 0.25em 1.3em;
    color: $asphalt;
    font-size: 0.85em;
    line-height: (23 / 17);
    // white-space: nowrap;
    @media screen and (max-width: $mobile-nav) {
      padding: 0.3em $gutter;
    }
  }
  a {
    &:hover {
      background: $cloud;
    }
  }
  .leaf { // Last depth of item
    a,
    .nolink {
      @include link-arrow(before, $nepal, $cis-orange);
      padding-left: 1.6em;
      @media screen and (max-width: $mobile-nav) {
        padding-left: calc(#{$gutter} + 1em);
      }
      &::before {
        position: absolute;
        top: 0.65em; // 0.4em + padding of parent <a>
        left: 0.3em;
        @media screen and (max-width: $mobile-nav) {
          left: $gutter;
          margin-left: 0;
        }
      }
      &.active,
      &.active-trail {
        @include link-arrow(before, $cis-orange);
        color: $cis-blue;
        font-weight: 600;
        &::before {
          @media screen and (max-width: $mobile-nav) {
            margin-left: 0;
          }
        }
      }
    }
  }
  .expanded { // Second-level and deeper parent items
    @include nav-expanded;
    @media screen and (max-width: $mobile-nav) {
      padding: 0.3em $gutter;
    }
  }
  @media screen and (max-width: $mobile-nav) {
    padding: 0;
  }
}

// ==============================
// Mega menu
// ==============================

// Display mega menu items inline (assumes a third level of navigation)
.mega-menu {
  .menu-level-2 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: fixed; // Don't align it to the top parent item like normal submenus
    top: $header-height;
    // left: 0;
    // transform: none;
    width: 100%;
    width: calc(#{$container-width} - #{$gutter} * 2); // Align visually with .container padding
    max-width: 100%;
    @media screen and (min-width: $desk) { // Same as .container in _layout
      width: calc(#{$container-width} - 2.6em * 2);
    }
    @media screen and (max-width: $desk) {
      width: 100%;
    }
    @media screen and (max-width: $mobile-nav) {
      display: block;
      position: static;
    }
  }
  .item-level-2 {
    flex: 0 1 auto;
    padding: 0 0.3em;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    @media screen and (max-width: $mobile-nav) {
      padding: 0;
    }
  }
  > .nolink {
    @include nav-expanded-nolink;
  }
}

.logged-in {
  .mega-menu {
    .menu-level-2 {
      top: calc(#{$header-height} + #{$admin-height});
      @media screen and (max-width: 1024px) { // Admin slicknav breakpoint
        top: calc(#{$header-height} + #{$slicknav-height});
      }
    }
  }
}

// ==============================
// Main menu
// ==============================

// Block-level wrapper class
.header-menu-main {
  display: flex; // Move nav to right side of div
  flex: 0 0 auto;
  justify-content: flex-end;
  width: 100%;
  @media screen and (max-width: $mobile-nav) {
    display: block;
  }
  .item-level-1 {
    position: relative;
    > a,
    > .nolink {
      font-size: 0.8em;
      line-height: (18 / 14);
      &.button {
        margin-bottom: 0.9em; // Align visually with other nav items
      }
      &:not(.button) { // Don't apply this stuff to buttons
        @include link($cis-blue);
        overflow: hidden; // For the borders
        // padding-bottom: 1.3em;
        padding-bottom: calc(1em + #{$header-gutter}); // Add header-gutter to normal padding
        &::before {
          @include transition(transform);
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 0.5em;
          background: $cis-orange;
          @media screen and (max-width: $mobile-nav) {
            content: none;
          }
        }
        &.active-trail,
        &.active {
          font-weight: 700;
        }
        // &:hover {
        //   color: $cis-orange;
        // }
      }
    }
    > a:not(.button) {
      &:hover {
        color: $cis-orange;
        @media screen and (max-width: $mobile-nav) {
          background: $cloud; // Same as submenu a:hover
          color: $cis-blue;
        }
      }
    }
    &:hover { // Keep the active state on parent link when hovering over its submenu
      > a,
      > .nolink {
        &:not(.button) {
          // color: $cis-orange;
          // font-weight: 700;
          &::before {
            transform: translateY(-100%);
          }
        }
      }
    }
    @media screen and (max-width: $mobile-nav) { // Pretty much copy .expanded styles
      @include nav-expanded;
      > a,
      > .nolink {
        &:not(.button) {
          padding: 0.3em $gutter;
        }
        &.button {
          margin: 1em $gutter;
          font-size: 1em;
        }
      }
    }
  }
  .button {
    @media screen and (max-width: $mobile-nav) { // Center the buttons and fill the nav width
      display: block;
      margin-right: 2.6%;
      margin-left: 2.6%;
      text-align: center;
    }
  }
}

// ==============================
// Secondary menu
// ==============================

.header-menu-secondary {
  margin-bottom: 0.6em;
  .item-level-1 {
    position: relative;
    > a,
    > .nolink {
      @include link($denim, $cis-blue);
      font-size: 0.7em;
      z-index: $submenu-z + 6; // More than menu-level-2
      &.active,
      &.active-trail {
        font-weight: 700;
      }
      @media screen and (max-width: $mobile-nav) {
        padding: 0.3em $gutter;
        font-size: 0.9em;
      }
      &::after {
        vertical-align: middle;
        // width: 1em;
        // height: 1em;
      }
    }
    &.expanded {
      // @media screen and (max-width: $mobile-nav) {
      //   &:hover {
      //     > .nolink {
      //       font-weight: 700;
      //     }
      //   }
      // }
      > .nolink {
        @media screen and (max-width: $mobile-nav) { // Have to override these overly specific expanded styles for mobile
          @include link($denim, $denim);
          padding: 0.3em $gutter;
          font-family: $roboto;
          font-size: 0.9em;
          line-height: 1;
          &::after {
            vertical-align: middle;
            // width: 1em;
            // height: 1em;
          }
        }
        // @include nav-expanded-nolink;
        // font-family: $roboto;
        // font-size: 0.9em;
      }
    }
  }
  .menu-level-2 {
    z-index: $submenu-z + 5;
  }
}

.menu-dispute {
  > a,
  > .nolink {
    @include inline-icon('dispute', $denim, $cis-blue);
    &::after {
      width: 1em;
      height: 1em;
    }
    @media screen and (max-width: $mobile-nav) {
      @include inline-icon('dispute', $denim, $denim); // Override hover style
      &::after {
        // margin-bottom: 0;
      }
    }
  }
}

.menu-client-login {
  > a,
  > .nolink {
    @include inline-icon('login', $denim, $cis-blue);
    &::after {
      width: 0.7em;
      height: 1em;
    }
    @media screen and (max-width: $mobile-nav) {
      @include inline-icon('login', $denim, $denim); // Override hover style
      &::after {
        // margin-bottom: 0;
      }
    }
  }
}

// ==============================
// Search block
// ==============================

.header-search {
  margin-bottom: 0.6em;
  .search-form {
    margin: 0;
  }
}

// Derived some from https://tympanus.net/codrops/2013/06/26/expanding-search-bar-deconstructed
.search-wrapper {
//   display: flex;
//   align-items: center;
  // overflow: hidden;
  position: relative;
  min-width: 11em;
  padding-left: 0.8em;
//  // padding-right: calc($nav-padding-horizontal - 0.3em); // Visually align the search icon with the last nav item
//   border-radius: $border-round;
  background: none;
  font-size: 0.8em;
  backface-visibility: hidden;
  .form-item,
  .form-actions {
    padding: 0;
    margin: 0;
  }
  input {
    padding: 0.3em;
  }
  @media screen and (max-width: $mobile-nav) {
//     justify-content: center;
//     margin: 0 auto;
//     background: $white;
    padding: 0;
    margin: 1em $gutter;
    font-size: 1em;
  }
}

.search-input {
//   @include transition(width, 0.19s, ease);
//   width: 0;
  input {
//     visibility: hidden;
//     opacity: 0;
//     outline: none;
    height: 100%;
    padding-right: 2em;
//     padding: 0;
//     border: 0;
//     background: none;
//     appearance: none;
//     @media screen and (max-width: $mobile-nav) {
//       // Lots of stuff copied from the search-active state
//       visibility: visible;
//       opacity: 1;
//       padding: 0.3em;
//       padding-right: 0;
//       padding-left: $nav-padding-horizontal-small;
//     }
  }
//   @media screen and (max-width: $mobile-nav) {
//     width: 100%;
//   }
}

.search-actions {
  flex: 0 0 auto;
  // position: relative;
  position: absolute;
  top: 0;
  right: 0;
  width: 2em;
  height: 100%;
  cursor: pointer;
  color: $denim;
  &:hover,
  &:focus {
    color: $cis-blue;
  }
  input[type=submit] { // Hide submit button behind search icon with same size
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 0;
    appearance: none;
  }
//   @media screen and (max-width: $mobile-nav) {
//     @include link-gray-light;
//     // color: $gull-gray;
//     &:hover {
//       // color: $shuttle-gray;
//     }
//   }
}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  width: 100%;
  height: 100%;
  // padding: 0 0.3em;
  // padding: 0.3em $nav-padding-horizontal; // Align the search icon visually with the last nav item
  line-height: 0;
  text-align: center;
  svg {
    display: inline-block;
    vertical-align: middle;
    width: 1.3em;
    height: 1.3em;
    margin: 0;
  }
//   @media screen and (max-width: $desk) {
//     padding-right: $nav-padding-horizontal-small;
//     padding-left: $nav-padding-horizontal-small;
//   }
}

.search-active {
//   margin-left: 1em;
//   background: $white2;
//   .search-input {
//     width: 11em;
//     input {
//       visibility: visible;
//       opacity: 1;
//       padding: 0.3em;
//       padding-right: 0;
//       padding-left: $nav-padding-horizontal;
//       &:focus {
//         box-shadow: none;
//       }
//       @media screen and (max-width: $desk) {
//         padding-left: $nav-padding-horizontal-small;
//       }
//     }
//   }
  .search-actions {
    input[type=submit] { // Bring submit button in front of search icon, but still invisible
      z-index: 15;
    }
  }
//   @media screen and (max-width: $mobile-nav) {
//     margin: 0;
//     background: $white;
//     .search-input {
//       width: 100%;
//     }
//   }
}
