// =========================
// Pagers
// =========================

.item-list {
  .pager {
    @include ul-override;
    li {
      padding: 0.6em;
      margin: 0;
    }
    .pager-current {
      color: $cis-blue;
    }
  }
}
