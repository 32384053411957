// ==============================
// Basic pages
// ==============================

// ==============================
// Hero
// ==============================

.hero-background {
  position: absolute;
  z-index: 19;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: $container-width-raw * 1.3rem; // Little trick to add the unit
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: translateX(-50%);
  backface-visibility: hidden; // Hides a hairline that shows at the edge of the div with the gradients
  &::before,
  &::after {
    content: '';
    display: none; // Added for client feedback
    position: absolute;
    z-index: 10;
    top: 0;
    width: 15%;
    height: 100%;
  }
  &::before {
    left: 0;
    background: linear-gradient(to right, $cloud 0%, rgba($cloud, 0) 100%);
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, $cloud 0%, rgba($cloud, 0) 100%);
  }
}

.hero {
  overflow: hidden;
  position: relative;
  background-color: $cloud;
  color: $cis-blue;
  .container {
    position: relative;
    z-index: 20;
  }
  .column-container {
    position: relative;
    z-index: 15;
  }
  [class*=col-] {
    margin-bottom: 0;
    &:last-child { // It's just empty space, so hide it
      @media screen and (max-width: $mobile-nav) {
        display: none;
      }
    }
  }
}

.hero-content {
  @include last-item-margin;
  font-size: 1.1em;
  hr {
    max-width: 80%;
  }
}
