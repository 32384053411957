// ==============================
// Affiliations
// (Partnerships, providers, memberships)
// ==============================

.affiliations {
  margin: 2.1em 0;
  .view-content {
    @include grid-container;
  }
  .views-row {
    @include grid-tile;
  }
  .modal-preview {
    .image-grow {
      cursor: pointer;
      // margin-bottom: 0.8em;
    }
  }
  .modal-inner {
    > .column-container {
      margin-bottom: 0;
    }
    .affiliation-image {
      max-width: 16em;
      margin: 0 auto;
      text-align: center;
    }
  }
}

// ==============================
// Affiliations homepage
// ==============================

.affiliations-homepage {
  @include overflow-container;
  .view-content {
    @include grid-container;
    @include overflow-items;
  }
  .views-row {
    width: 16%;
    min-width: 8em;
    padding: 0 0.6em;
    margin: 1em auto;
  }
}
