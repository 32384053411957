// ==============================
// Homepage specific
// ==============================

// ==============================
// Stats
// ==============================

.home-stats {
  @media screen and (max-width: $mobile-nav) {
    display: block;
    [class*=col-] {
      display: block;
      max-width: 20em;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.home-stat {
  display: block;
  padding-bottom: 0.19em;
  margin-bottom: 0.3em;
  border-bottom: $border;
  color: $cis-blue;
  font-family: $sofia;
  font-size: 3em;
  line-height: (85 / 76);
  font-weight: 600;
  text-align: center;
}

// ==============================
// Solutions icons
// ==============================

.home-solutions-background {
  @include inline-icon('background-screening', $cis-orange, $pseudo: 'before');
}

.home-solutions-technology {
  @include inline-icon('hcm-technology', $cis-orange, $pseudo: 'before');
}

.home-solutions-intelligence {
  @include inline-icon('executive-intelligence', $cis-orange, $pseudo: 'before');
}

.home-solutions-background,
.home-solutions-technology,
.home-solutions-intelligence {
  position: relative;
  padding-left: 2.6em;
  &::before {
    position: absolute;
    top: 0em;
    left: 0;
    width: 1.9em;
    height: 1.9em;
    margin: 0;
  }
}

// ==============================
// Carousel
// ==============================

.carousel-home {
  background: $cloud;
  color: $cis-blue;
  text-align: center;
  .owl-carousel {
    margin-top: 2.1em;
  }
}
