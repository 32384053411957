// ==============================
// Call to action
// ==============================

.icon-cta {
  display: block;
  width: 1.6em;
  height: 1.6em;
  margin: 0 0 1em;
  color: $cis-blue;
}

// ==============================
// Referenced CTAs in pages
// ==============================

.cta {
  padding: $section-gutter * 0.6 0 $section-gutter * 0.8;
  background: $cis-orange;
  color: $cis-blue;
  .field-item {
    @include last-item-margin;
  }
  .column-container {
    align-items: center;
    > *:first-child {
      flex: 0 1 auto;
    }
    > *:last-child {
      // flex: 1 0 auto;
    }
  }
  h3 {
    // font-size: 1.3em;
  }
  .button {
    @include button(
      $background: $cis-blue,
      $background-hover: $avocado,
      $color: $avocado,
      $color-hover: $cis-blue,
      $arrow-color: $lime,
      $arrow-hover: $cis-blue
    );
    font-size: 1em;
    white-space: normal;
  }
}
