// ==============================
// Admin sections on front-end
// ==============================

body.admin-menu.adminimal-menu::before {
  content: none;
}

#admin-menu {
  height: $admin-height;
  ul {
    @include ul-override;
  }
}

#admin-menu,
#admin-menu .dropdown {
  z-index: 2000;
}

@media screen and (min-width: 1025px) {
  .logged-in {
    .sticky-header {
      top: $admin-height;
    }
    main {
      padding-top: calc(#{$header-height} + #{$admin-height}) !important;
    }
  }
}

// ==============================
// Slicknav mobile menu
// ==============================

.slicknav_menu {
  // position: relative;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: $slicknav-height;
  a {
    @include link-override;
  }
  ul {
    @include ul-override;
  }
}

@media screen and (max-width: 1024px) {
  .logged-in {
    .sticky-header {
      top: $slicknav-height;
    }
    main {
      padding-top: calc(#{$header-height} + #{$slicknav-height}) !important;
    }
  }
}

@media screen and (max-width: $mobile-nav) {
  .logged-in {
    main {
      padding-top: calc(#{$header-height-mobile} + #{$slicknav-height}) !important;
    }
    .region-header {
      top: $slicknav-height;
    }
  }
}

// ==============================
// Contextual Links
// ==============================

ul.contextual-links {
  @include ul-override;
  li {
    list-style: none;
    background: none !important;
    a {
      @include link-override;
      text-align: left;
    }
  }
}

div.contextual-links-wrapper {
  z-index: 500;
}

// ==============================
// Utilities section
// ==============================

.utilities {
  padding: $section-gutter / 2 0;
  .tabs {
    @include last-item-margin;
  }
}

ul.tabs.primary {
  @include ul-override;
  height: auto;
  padding: 0;
  border-bottom: 0;
  white-space: initial;
  text-align: center;
  li {
    display: inline-block;
    a {
      @include button;
      display: inline-block;
      height: auto;
      // padding: 0.6em 1.3em;
      margin: 0.5em;
      border: 0;
      border-bottom: 0;
    }
  }
}

// ==============================
// Status messages
// ==============================

div.messages {
  // @include white;
  @include last-item-margin;
  padding: 1em 1.3em;
  margin: 1em 0 !important; // Override the last-item-margin set by its parent
  border: 0;
  background: none;
  color: $asphalt;
  font-weight: 600;
  ul {
    @include ul-override;
  }
  a {
    @include link-override;
  }
}

div.status,
table tr.ok {
  background: $avocado;
}

div.error,
table tr.error {
  background: $blood;
  color: $white;
}

// ==============================
// CK ditor extras and overrides
// ==============================

.cke_editable {
  display: block; // Override flexbox on ckeditor body
  padding: 1em;
  font-size: 1.6em; // Roughly 16px
  [class^=col-],
  .expandable-title,
  .expandable-content {
    padding: 1em;
    border: 1px solid $cloud;
    background: rgba($cloud, 0.3);
  }
}

// ==============================
// Field collection links
// ==============================

.field-collection-view-links { // Similar to .utilities buttons
  @include ul-override;
  margin: 0;
  li {
    display: inline-block;
    a {
      @include button;
      display: inline-block;
      height: auto;
      margin: 0.5em;
      border: 0;
      border-bottom: 0;
    }
  }
}
